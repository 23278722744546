import { Container, Row , Col } from "react-bootstrap"
import { ArrowRightCircle } from "react-bootstrap-icons"

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: "#6163FF",
    border: '5px solid #000',
    boxShadow: 24,
    p: 4,
};

export const Banner = () => {
    const [open, setOpen] = React.useState(false);

    return (
        <section className="banner" id="home">
            <div class="container-xl">
                <p id="firstLine">VOTED FOR THE BEST</p>
                <p id="secLine">INTERACTIVE VIRTUAL</p>
                <p><span id="thirdLine">ESCAPE ROOM </span><span id="forthLine">in Singapore</span></p>
            </div>             
        </section>  
    );
} 

export default Banner;
