// Essentials
import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import Card from '@mui/material/Card';
import Rating from '@mui/material/Rating';
import StarBorderIcon from "@material-ui/icons/StarBorder";
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

import {
  BrowserRouter as Router,
  useHistory
} from 'react-router-dom';


// Material UI
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

// Display http message
import 'react-toastify/dist/ReactToastify.min.css';

//MDB 5
import 'mdb-react-ui-kit/dist/css/mdb.min.css' // https://mdbootstrap.com/docs/react/
import Image from '../../components/elements/Image';
// MDB 5
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardSubTitle,
  MDBCardImage,
} from 'mdb-react-ui-kit';
import { alignProperty } from '@mui/material/styles/cssUtils';

import "../../games.css"

const url = process.env.REACT_APP_API_URL;

const Rooms = ({
  ...props
}) => {


  // Styles
  const innerClasses = classNames(
    'section',
  );

  const outerClasses = classNames(
    'section center-content',
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
  );

  const outerTiles = classNames(
    'review-tiles-outer',
  );

  const splitWrap = classNames(
    'split-wrap'
  );

  // styling for difficuty level stars
  const useStyles = makeStyles(() => ({
    emptyStar: {
      color: "white"
    }
  }));


  const classes = useStyles();

  const scrollDown = () => {
    window.scrollTo({
      top: infoSection.current.offsetTop,
      behavior: 'smooth'
    });
  };

  // to overide material ui button default colour themes 
  const theme = createTheme({
    palette: {
      success: {
        main: '#f6c94e',
        contrastText: '#000000',
      },
      secondary: {
        main: '#fff',
        contrastText: '#000000',
      },
      subSecondary: {
        main: '#70140c',
        contrastText: '#fff',
      },
    },
  });



  const navigateSelectedGame = (gameType, gameID) => {
    sessionStorage.setItem('selectedGame', JSON.stringify({ gameId: gameID }));
    //console.log('gameType - ' + gameType);
    //console.log(sessionStorage.getItem('selectedGame'));

    if (gameType == 'TYPE1') {
      history.push('/purchaseRoom')

    }
    else {
      history.push('/purchaseHost')
    }
    //navigate('/admin/selectBids');
    //<Redirect to="/login" />
  }

  const navigateDetailedGame = (gameID) => {
    console.log('test')
    sessionStorage.setItem('selectedDetailedGame', JSON.stringify({ gameId: gameID }));
    //console.log('gameType - ' + gameType);
    //console.log(sessionStorage.getItem('selectedGame'));

    history.push('/individualGame')



  }

  const handleClick = (gameURL) => {
    window.location.replace(gameURL);
  }

  // Called Apis
  //const gameID = 1

  const [rooms, setAllRooms] = useState([]);

  const getAllRooms = async () => {
    await axios.post(`${url}/admin/allRooms`).then((response) => {

      // setAllRooms(response.data.data[0])
      setAllRooms(response.data.data)


    })
  };


  const infoSection = useRef(null);

  useEffect(() => {
    getAllRooms()
  }, []);


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const btnStyle = {
    fontWeight: 'bold',
    fontSize: 14,
    wordWrap: 'break-word',
    width: '90%',
    borderRadius: "10px",
    height: '40px',
    color: 'customColor.primary',
    position: "absolute",
    bottom: 20,
    margin: '70px',
  };

  const history = useHistory();

  const bookNow = () => {
    history.push('/purchase');
  };





  return (
    <section
      {...props}
      className={classNames(outerClasses)} id="rooms">
      <div className="container-xl" ref={infoSection}>
        <h3>Our Themed Escape Rooms</h3>
        <div className={tilesClasses}>
          <ThemeProvider theme={theme}>
            {rooms.map((rooms, index) => (
              <MDBCard className='gamesCard'>
                <div className='bg-image hover-zoom'>
                  {/*console.log('pic ' + rooms.gamePic)*/}
                  {/* <MDBCardImage src={require(`./../../assets/dbImage/rooms/${rooms.gamePic}`)} style={{ height: '220px' }} position='top' alt='...' /> */}
                  <MDBCardImage src={rooms.images[0]} style={{ height: '220px' }} position='top' alt='...' />
                </div>
                <MDBCardBody>
                  <div className='games-inner'>
                    <MDBCardTitle style={{ fontSize: '22px', fontWeight: 'bold' }}>{rooms.gameName}</MDBCardTitle>
                    <MDBCardSubTitle style={{ fontSize: '20px', fontWeight: 'bold', color: 'white' }}>
                      {rooms.currency} {rooms.normalPrice} per pax
                    </MDBCardSubTitle>
                    <MDBCardSubTitle style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '5px' }}>Difficulty Level</MDBCardSubTitle>
                    <MDBCardSubTitle style={{ padding: '20px', position: 'relative' }}>
                      {/* <StyledRating
                        name="customized-color"
                        value={parseInt(rooms.difficultyLvl)}
                        precision={1}
                        icon={<VpnKeyIcon fontSize="inherit" />}
                        emptyIcon={<VpnKeyOutlinedIcon fontSize="inherit" className={classes.emptyKey} />}
                      /> */}
                      <Rating
                        name="difficultyLevel"
                        value={parseInt(rooms.difficultyLvl)}
                        precision={1}
                        readOnly
                        style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '10px' }}
                        icon={<VpnKeyIcon fontSize="inherit" />}
                        emptyIcon={
                          // <StarBorderIcon fontSize="inherit" className={classes.emptyStar} />
                          <VpnKeyOutlinedIcon fontSize="inherit" className={classes.emptyStar} />

                        }
                      />
                    </MDBCardSubTitle>


                    <div className='cardBtnContainer' >
                      {/* book button */}
                      {/* <ul> */}

                      {/* <li style={{display: 'inline-block',listStyleType: 'none',marginTop: '10px'}}> */}
                      <Button
                        sx={btnStyle}
                        style={{ margin: '68px' }}
                        variant="contained"
                        color="secondary"
                        size='small'
                        onClick={() => navigateSelectedGame(rooms.gameType, rooms.gameID)} >Book
                      </Button>
                      {/* </li> */}



                      {/* learn more button */}
                      {/* <li style={{display: 'inline-block',listStyleType: 'none',marginTop: '10px'}}> */}
                      <Button
                        sx={btnStyle}
                        // style={{ marginBottom: '15px' }}
                        style={{ marginBottom: '25px' }}
                        variant="contained"
                        color='success'
                        size='small'
                        //Link component={Link} to={`/individualGame/${rooms.gameID}`}
                        onClick={() => navigateDetailedGame(rooms.gameID)}
                      > Learn More
                      </Button>
                      {/* </li> */}



                      {/* play online button */}
                      {/* <li style={{display: 'inline-block',listStyleType: 'none',marginTop: '10px'}}> */}
                      {/* {rooms.gameURL ?
                        <Button
                          sx={btnStyle}
                          style={{ margin: '-18px', color: 'white' }}
                          variant="contained"
                          color="subSecondary"
                          size='small'
                          onClick={() => handleClick(rooms.gameURL)}>Play Online Game
                        </Button> :
                        (<></>)
                      } */}
                      {/* </li> */}

                      {/* </ul> */}
                      {/*to={rooms.gameType == 'TYPE1' ? `/purchaseRoom/${rooms.gameID}` : 'https://google.com'} */}
                    </div>
                  </div>

                </MDBCardBody>
              </MDBCard>
            ))}
          </ThemeProvider>
        </div>
      </div>
    </section>

  );
}

export default Rooms;
