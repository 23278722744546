import React from "react";
import Groups2Icon from '@mui/icons-material/Groups2';
import EmojiObjectsRoundedIcon from '@mui/icons-material/EmojiObjectsRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import { Card, CardContent, Typography } from '@mui/material'

const contentStyle = {
    marginBottom: '20px',
}

const cardStyle = {
  margin: '20px',
  height: '435px',
  backgroundColor: '#70140c',
  borderRadius: '20px',
  width: '350px',
};

const iconStyle = {
    fontSize: '60px',
    color: 'white',
    margin: '10px',
};

const titleStyle = {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    padding: '10px',
    wordWrap: 'break-word',
    maxWidth:'320px',
};

const bodyStyle = {
    fontSize: 18,
    color: 'white',
    wordWrap: 'break-word',
    marginBottom: '10px',
};

export const LearningPoint = () => {
  return (
    <>
        <section id="learningpoint" className="sec-padding">
            <div className="container-xl">
                <h3>Why Should You Play?</h3>

                <div className="box">
                    <Card sx={cardStyle}>
                        <CardContent sx={contentStyle} >
                        <EmojiObjectsRoundedIcon style={iconStyle} />
                            <Typography sx={titleStyle}>Enhances Problem Solving</Typography>
                            <Typography sx={bodyStyle} variant="body2">
                            It's the perfect opportunity to either hone or showcase your observation, problem-solving and analytical skill with challenging and mind-blowing activities in the escape room. 
                            </Typography>
                            <Typography sx={bodyStyle} variant="body2">
                            Ready yourself to immerse in competitiveness over completing the game.
                            </Typography>
                        </CardContent>
                    </Card>
                        
                    <Card sx={cardStyle}>
                        <CardContent>
                        <Groups2Icon style={iconStyle}/>
                            <Typography sx={titleStyle}>Team Building Activity</Typography>
                            <Typography sx={bodyStyle} variant="body2">
                            Melt some ice and tighten some bonds. By the nature of the games, communication will be key to survival. 
                            </Typography>
                            <Typography sx={bodyStyle} variant="body2">
                            It's a great way to engage everyone into speaking out their mind, participate in decision-making and even emerging leadership inside the circle.
                            Even foes can become friends after the session!

                            </Typography>
                        </CardContent>
                    </Card>
    
                    <Card sx={cardStyle}>
                        <CardContent>
                        <PublicRoundedIcon style={iconStyle}/>
                            <Typography sx={titleStyle}>Anywhere is Possible</Typography>
                            <Typography sx={bodyStyle} variant="body2">
                            There is no limit to greatness. Enjoyable anywhere around the globe as long as you have a working internet connection.
                            </Typography>
                            <Typography sx={bodyStyle} variant="body2">
                            Playing in a setting for a birthday celebration or company bonding with teams across the world, either is possible.
                            </Typography>
                        </CardContent>
                    </Card>

                </div> 
            </div>
        </section>
    </>
  );
};
export default LearningPoint;
