import React from 'react';
import { SectionTilesProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const SellingPoint = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  return (    
    <section id="sellingpoint" class="sec-padding">
            <div class="container-xl">
                <h3 class="sellingLine" >Why Choose JNR Entertainment?</h3>

                <div class="box">
                  <div class="row">
                    <div class="col-md-6">
                      <h4><i class="icon-calendar fa-lg" style={{ marginRight: "20px"}}></i>Experience & Expertise</h4>                                   
                        <p className="m-0 text-sm" class="text" >
                          With over 10 years of experience in the industry and over 5000 events organised, rest assured that we know what to do and can meet your requirements!
                        </p>
                    </div>

                    <div class="col-md-6">
                      <h4><i class="icon-trophy fa-lg" style={{ marginRight: "20px"}}></i>Award Winning</h4>                                   
                        <p className="m-0 text-sm" class="text">
                        We are proud to be the winner of several awards including Spirit of Enterprise and Singapore Young Entrepreneurs Award.
                        </p>
                    </div>
                  </div>    
                  <div class="row">
                    <div class="col-md-6">
                        <h4><i class="icon-time fa-lg" style={{ marginRight: "20px"}}></i>Unparalleled Service</h4> 
                        <p className="m-0 text-sm" class="text">
                        Our dedicated team will get back to your enquiries within 24 hours. If you require urgent assistance, do give us a call to reach us!
                        </p>               
                    </div>
                    <div class="col-md-6">
                      <h4><i class="icon-thumbs-up fa-lg" style={{ marginRight: "20px"}}></i>Reliable Services</h4>         
                      <p className="m-0 text-sm" class="text">
                      JNR offers a huge range of services and we promise to provide you with the trusted and quality services you need.
                      </p>                          
                    </div>
                  </div>    
                </div> 
            </div>
        </section>
  );
}

SellingPoint.propTypes = propTypes;
SellingPoint.defaultProps = defaultProps;

export default SellingPoint;