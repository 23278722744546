// Essentials
import React from 'react';
import classNames from 'classnames';

// Material UI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//MDB 5
import 'mdb-react-ui-kit/dist/css/mdb.min.css' // https://mdbootstrap.com/docs/react/

const FAQ = ({
  imageFill,
  ...props
}) => {

  // Styles
  const innerClasses = classNames(
    'section',
  );

  const outerClasses = classNames(
    'section center-content',
  );


  return (
    <section 
      {...props}
      className={classNames(outerClasses)}>
      <div className={classNames(innerClasses, 'container-full')}>
        <div className='container-xl'>
          <h3>Frequently Asked Questions</h3>
          <div className='container'>
            <Accordion className='accordion-content' disableGutters={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white"}}/>}
                aria-controls="panel1a-content"
                className='accordionContent'
                style={{
                  backgroundColor: "#151719",
                  color: "white",
                  borderTop: '1px solid white', 
                  }}
              > 
                <Typography style={{ fontWeight: 'bold'}}>
                  Do I need an account to make a booking?
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#151719" , color: "white"}}>
                <Typography className='accordion-details'>
                  You do not require an account to make a booking for our virtual escape room. However, you do need a valid email adddress to receive the game code.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className='accordion-content' disableGutters={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white"}}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{
                  backgroundColor: "#151719",
                  color: "white",
                  borderTop: '1px solid white', 
                  }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  Is there a minimum purchase quantity per booking?
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#151719" , color: "white"}}>
                <Typography className='accordion-details'>
                  No there isn't any minimum quantity required for each booking.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQ;