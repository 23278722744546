
import * as React from 'react';
import { useState } from 'react';
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

// styling for buttons 
const btnStyle = {
    fontWeight: 'bold',
    fontSize: 14,
    wordWrap: 'break-word',
    width: '11rem',
    borderRadius: "10px",
    height: '40px',
    marginTop: '30px',
    marginBottom: '40px',
    color: 'customColor.primary',
    display: 'flex',
};


// to overide material ui button default colour themes 
const theme = createTheme({
    palette: {
        success: {
        main: '#f6c94e',
        contrastText: '#000',
        },
        secondary: {
        main: '#1CB68B',
        contrastText: '#fff',
        },
    },
});

function Counter () {
    const [counterOn, setCounterOn] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (          
        <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
            <section id="counter" class="sec-padding">            
                <div class="container-xl">
                <p class="text">
                Singapore's best interactive virtual escape room online game built-in with highly interactive clues and tools. With over 10 rooms to
                explore from a single escape room game online, you can work together with your team to solve puzzles and uncover the mystery.
                Put on your thinking cap and join forces with your fellow detectives to solve the mystery...
                </p>
                <ThemeProvider theme={theme}>
                <Button onClick={handleOpen}
                    sx={btnStyle}
                    variant="contained"
                    color='success'                                
                    size='small'>Read More  
                </Button>
                </ThemeProvider>

                <Modal 
                    open={open}
                    onClose={handleClose}
                    center>
                        
                    <Typography id="modal-modal-description" style={{ color: 'black' , fontSize: '20px' , overflow: "hidden"}}>
                            SGEscapeRoom is an interactive virtual escape room built-in with highly intriguing themes.
                            With over 10 rooms to explore, people can find engaging story-telling in different themes with captivating clues and tools.
                            The objective is to work together with your friends or colleagues to 'escape' or fulfill the game objective by gathering clues and solving puzzles all within 1.5 hours.
                            Uncover the mysteries by putting on your thinking cap and join forces to solve. 
                            <br></br><br></br>
                            SGEscapeRoom is a great multiplayer game that enhances teamwork and analytical skills by putting players into a unique situation where they have to communicate and understand each other in an elevated way.
                            Built-in with interactive safe locks, mind-blowing activities, and even authentic unlocking padlock mechanisms - our online escape room games takes inspiration from the real-life escape room to keep the adrenaline level high.
                            As it is a 100% virtual game experience, people can now experience the thrill from anywhere with anyone without restriction and without leaving the house.
                            It is the best virtual activity recommended for team bonding and team building.
                            Our SGEscapeRoom is also a fun way to celebrate different occasions such as birthdays and social gatherings! 
                    </Typography>
                </Modal>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="count"> 
                                <p class="number">
                                    &gt; <CountUp start={0} end={10} duration={3} delay={0}/>
                                </p>
                                <p>rooms to explore</p> 
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="count">
                                <p class="number">
                                    <CountUp separator=',' start={0} end={10000} duration={3} delay={0}/>
                                </p>
                                <p>games played</p> 
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="count"> 
                                <p class="number">
                                    <CountUp separator=',' start={0} end={100000} duration={3} delay={0}/>
                                </p>                                
                                <p>players successfully escaped</p>  
                            </div>                        
                        </div>   

                    </div>
                </div>
            </section>
        </ScrollTrigger>
    );
};


export default Counter;
